import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import React, { useEffect, useState } from 'react';
import {
  FormProvider,
  RHFSelect,
  RHFTextField,
} from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import DownloadPasswordStorage from '#/components/pages/DigitalAssets/download-password-storage';
import {
  invalidateDigitalAssetsQueries,
  useAddDigitalAsset,
} from '#/api/digitalAssets';
import { FormValues } from '#/types/digitalAssets';
import {
  AFTER_DEATH_OPTIONS,
  ASSET_TYPES,
} from '#/components/pages/DigitalAssets/formOptions';
import { useGetBeneficiaries } from '#/api/beneficiaries';
import Iconify from '#/components/shared/ui/Iconify';
import Beneficiaries from '#/components/pages/LastWill/steps/content/Inheritors/childrens/Beneficiary/Beneficiaries';
import useAuth from '#/hooks/useAuth';

type Props = {
  hideForm: () => void;
};

export default function DigitalAssetForm({ hideForm }: Props) {
  const [addNewBenefiter, setAddNewBenefiter] = useState(false);
  const [lastAddedBenefiter, setLastAddedBenefiter] = useState<boolean>(false);

  const { translate } = useLocales();
  const { user } = useAuth();

  const { mutateAsync: addDigitalAsset, isLoading } = useAddDigitalAsset();

  const { data: beneficiaries } = useGetBeneficiaries();

  const defaultValues = {
    name: '',
    asset_type: '',
    after_death: '',
    access_info: '',
    beneficiary: '',
  };

  const methods = useForm<FormValues>({ defaultValues });
  const { handleSubmit, watch, setValue } = methods;

  const onSubmit = async (data: FormValues) => {
    try {
      await addDigitalAsset(data);
      toast.success(
        String(translate('toast_notifications.success.addDigitalAsset'))
      );
      invalidateDigitalAssetsQueries.getDigitalAssets();
      hideForm();
    } catch (e) {
      toast.error(
        String(translate('toast_notifications.error.addDigitalAsset'))
      );
    }
  };

  const handleLastAdded = () => {
    setLastAddedBenefiter(true);
  };

  useEffect(() => {
    if (lastAddedBenefiter && beneficiaries) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setValue('beneficiary', beneficiaries[beneficiaries?.length - 1]?.id);
    }
  }, [lastAddedBenefiter, beneficiaries]);

  return addNewBenefiter ? (
    <Beneficiaries
      externalRequest={addNewBenefiter}
      setNewBeneficiary={setAddNewBenefiter as any}
      handleLastAdded={handleLastAdded}
    />
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DownloadPasswordStorage />
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={1}
        sx={{ marginBottom: 2 }}
      >
        <RHFTextField
          name="name"
          label={String(translate('global.formLabels.name'))}
        />
        <Stack direction="row" spacing={1}>
          <RHFSelect
            name="asset_type"
            label={String(translate('global.formLabels.type'))}
            sx={{ width: 200 }}
          >
            {ASSET_TYPES.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {String(translate(`digitalAssets.assetTypes.${option.value}`))}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect
            name="after_death"
            label={String(translate('global.formLabels.afterDeath'))}
            sx={{ width: 200 }}
          >
            {AFTER_DEATH_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {String(
                  translate(`digitalAssets.afterDeathOptions.${option.value}`)
                )}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
      </Stack>
      {watch('asset_type') === 'phone_provider' && (
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          sx={{ marginY: 2 }}
        >
          <Typography variant="subtitle1">
            {String(translate('digitalAssets.yourPhoneNumber'))}:
          </Typography>

          <Typography variant="subtitle1">{user?.mobile_phone}</Typography>
        </Stack>
      )}
      n
      <Stack spacing={2}>
        <RHFTextField
          multiline
          rows={4}
          name="access_info"
          label={String(translate('global.formLabels.access'))}
        />
        {watch('after_death') === 'inherit' && (
          <RHFSelect
            name="beneficiary"
            label={String(translate('global.beneficiary'))}
          >
            <MenuItem onClick={() => setAddNewBenefiter(true)}>
              <Iconify icon="mdi:account-plus" color="text.secondary" />
              <Typography color="text.secondary" sx={{ textTransform: 'none' }}>
                {String(translate('global.addNewBeneficiary'))}
              </Typography>
            </MenuItem>
            {beneficiaries?.map((beneficiary) => (
              <MenuItem key={beneficiary.id} value={beneficiary.id}>
                {beneficiary.name}
              </MenuItem>
            ))}
          </RHFSelect>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{ marginTop: 2 }}
        spacing={2}
      >
        <Button onClick={hideForm} variant="outlined">
          <Typography>{String(translate('global.cancel'))}</Typography>
        </Button>
        <LoadingButton type="submit" variant="contained" loading={isLoading}>
          <Typography>{String(translate('global.save'))}</Typography>
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
